import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <SEO
            title="404: Not found"
            doNotAddNameSuffix={true}
            description="404 page, you hit dead end."
            pagePath={"/404"}
        />
        <h1>Not Found</h1>
        <p>Haiyo, this is a dead link. There is nothing to see here.</p>
    </Layout>
);

export default NotFoundPage;
